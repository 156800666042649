import React from "react";
import styles from "./style.module.scss";
import { brandList } from "./footerConstant";
import { useMediaPredicate } from "react-media-hook";
import { REACT_APP_VERSION } from "../../constant/env";

const Footer = () => {
  const lessThan640 = useMediaPredicate("(max-width: 640px)");

  const linksSectionJSX = () =>
    process.env.REACT_APP_VERSION === "v41" ? (
      <></>
    ) : (
      <div className={styles.links}>
        <section>
          <h1>
            KatsuWINについて <hr />
          </h1>

          <a href="https://www.katsuwin.io/page/agent">KatsuWINは何ですか？</a>
          <a href="https://www.katsuwin.io/page/cooperation">
            紹介者プログラム
          </a>
          <a href="https://www.katsuwin.io/page/support?language=jp">
            サポートセンター
          </a>
          <a href="https://www.katsuwin.io/">KATSUWIN.io</a>
        </section>

        <section>
          <h1>
            セキュリティ
            <hr />
          </h1>

          <a href="https://www.katsuwin.io/page/terms">利用規約</a>
          <a href="https://www.katsuwin.io/page/privacy">
            プライバシーポリシー
          </a>
          <a href="https://www.katsuwin.io/page/cookie-policy">
            クッキー(cookie)ポリシー
          </a>
          <a href="https://www.katsuwin.io/page/rule">
            キャンペーン一般利用規約
          </a>
        </section>

        <section>
          <h1>
            出入金方法 <hr />
          </h1>
          <img src="/footer/bank.png" alt="bank" width={77} />
          <img src="/footer/tether.png" alt="tether" width={68} />
          <img src="/footer/tigerPay.png" alt="tiger pay" width={87} />
          <img src="/footer/JCB.png" alt="JCB" width={22.85} />
        </section>
      </div>
    );

  const snsSectionJSX = () =>
    process.env.REACT_APP_VERSION === "v41" ? (
      <></>
    ) : (
      <section className={styles.sns_section}>
        <h1>KatsuWINのSNSをフォロー</h1>
        <div className={styles.sns_group}>
          <a href={process.env.REACT_APP_TWITTER_URL}>
            <img src="/footer/twitter.svg" alt="twitter" />
          </a>
          <a href="https://www.instagram.com/katsuwinonline/">
            <img src="/footer/instagram.svg" alt="instagram" />
          </a>
          <a href="https://www.youtube.com/@Katsuwinonline">
            <img src="/footer/youtube.svg" alt="youtube" />
          </a>
        </div>
      </section>
    );

  const brandsSectionJSX = () =>
    process.env.REACT_APP_VERSION === "v41" ? (
      <></>
    ) : (
      <section className={styles.brands}>
        {brandList.map((brand) => (
          <img
            src={`/footer/brands/${brand.name}.png`}
            width={lessThan640 ? brand.width : brand.pcWidth}
            alt={brand.name}
            key={brand.name}
          />
        ))}
      </section>
    );

  const licenseSectionJSX = () => {
    return REACT_APP_VERSION === "v40" ? (
      <section className={styles.license}>
        <img src="/footer/license.png" alt="license" />
        <h1>
          KatsuWINは、Initial Singularity
          Limitedによって所有および運営されております。登録番号: 15679 登録住所:
          Hamchako, Mutsamudu, Autonomous Island of Anjouan Union of
          Comoros.問い合せ先: support@katsuwin.ai
        </h1>
        <p>
          KatsuWINはライセンスを取得しており、コモロ連合アンジュアン自治島政府によって規制されており、ライセンス番号:
          15679に基づいて運営されております。KatsuWINはすべての規制遵守条件を満たしており、あらゆる賭けとギャンブルのゲームの運営を行う法的権限を与えられております。
        </p>
      </section>
    ) : (
      <section className={styles.antillephone}>
        <img src="/footer/redCircle.png" alt="Antillephone" />
        <h1>KatsuWIN は Antillephone ライセンスを保有しています</h1>
        <p>
          KatsuWINは2023年6月28日よりAntillephoneライセンス#8048/JAZ2021-180を正式に保有しており、Antillephoneはキュラソー島法律によって2022年8月7日より成立した有限会社であり、Master
          Gaming License#8048/JAZ.doingを保有しております。
        </p>
      </section>
    );
  };

  return (
    <footer className={styles.footer}>
      {linksSectionJSX()}

      {snsSectionJSX()}

      {brandsSectionJSX()}

      {licenseSectionJSX()}

      <section className={styles.logo_section}>
        <img src="/footer/footerLogo.png" alt="KatsuWIN logo" />
        <p>© KatsuWIN</p>
      </section>
    </footer>
  );
};

export default Footer;
