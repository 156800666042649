import { ModalType } from "@katsuwin/share-components/src/types/modal";
import React from "react";

export interface AppContextProps {
  signUpModal: ModalType;
  setSignUpModal: React.Dispatch<React.SetStateAction<ModalType>>;
}

export const defaultValue: AppContextProps = {
  signUpModal: "",
  setSignUpModal: () => {},
};

export const AppContext = React.createContext<AppContextProps>(defaultValue);
