import React, { useState } from "react";
import styles from "./style.module.scss";
import { cardInfoList } from "./vipConstant";
import Button from "../Button";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Mousewheel } from "swiper/modules";

const VIPRewards = () => {
  const [selected, setSelected] = useState(2);

  return (
    <section className={styles.section}>
      <h1>KatsuWINのVIPクラブ</h1>
      <p>
        KatsuWINは皆様の日頃よりのご愛顧に心から感謝申し上げます。現金ゲームをプレイするたびにVIPレベルを上げます。VIPレベルを上げると、より多くのお金を稼ぐことができます。レベルアップボーナスを始めとして、毎月ボーナス、お誕生日ボーナストなど豊富な賞金があり、ゲーム体験が向上します。
      </p>
      <Swiper
        modules={[Mousewheel]}
        mousewheel={true}
        className={styles.cards_group}
        initialSlide={1}
        spaceBetween={-135}
        slidesPerView={3.5}
        breakpoints={{
          640: { slidesPerView: 9, spaceBetween: -180 },
        }}
      >
        {cardInfoList.map((info, index) => {
          const cardStyle =
            cardInfoList[selected].id === info.id
              ? "scale-[115%] py-[15px]"
              : "hover:scale-110 transition-transform duration-300 ease-in-out cursor-pointer py-[15px]";

          return (
            <SwiperSlide key={info.id}>
              <img
                className={cardStyle}
                src={info.optionImage}
                alt={info.id}
                key={info.id}
                onClick={() => setSelected(index)}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className={styles.card_info_box}>
        <img src={cardInfoList[selected].image} alt="" />
        <h1>
          <span>{cardInfoList[selected].id}</span>のリワードと福利
        </h1>
        <p>
          昇格のボーナス：
          <span style={{ color: "#b3a982" }}>
            {cardInfoList[selected].promotion}
          </span>
        </p>
        <p>
          誕生日ボーナス：
          <span style={{ color: "#9c9471" }}>
            {cardInfoList[selected].birthday}
          </span>
        </p>
        <p>
          ライブカジノリベート：
          <span style={{ color: "#988765" }}>
            {cardInfoList[selected].monthly}
          </span>
        </p>
        <p className="pb-[40px]">
          スロットリベート：
          <span style={{ color: "#646358" }}>{cardInfoList[selected].bet}</span>
        </p>
        <Button link={process.env.REACT_APP_REGISTER_URL} />
      </div>
    </section>
  );
};

export default VIPRewards;
