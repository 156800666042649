import React from "react";
import styles from "./styles.module.scss";
import free_5000 from "./images/mobile/free_5000.png";
import Button from "../Button";
import { REACT_APP_VERSION } from "../../constant/env";

const contentJSX = (() => {
  return REACT_APP_VERSION === "v40" ? (
    <>
      <div className={styles.bg} />
      <Button link={process.env.REACT_APP_REGISTER_URL} />
    </>
  ) : (
    <>
      <h1>まずは無料で登録</h1>
      <div className={styles.sign}>新規登録特典</div>
      <img
        className={styles.free_5000_img}
        src={free_5000}
        alt="5000 free"
      ></img>
      <p className="pt-[29px] pb-[40px]">
        入金不要！
        <br />
        ライブカジノや スロットにすぐに使える！
      </p>
      <div className="w-[75.8%] flex justify-center">
        <Button link={process.env.REACT_APP_REGISTER_URL} />
      </div>
    </>
  );
})();

const RegisterForFree = () => {
  return (
    <section
      className={
        REACT_APP_VERSION === "v40" ? styles.section_v40 : styles.section_v41
      }
    >
      {contentJSX}
    </section>
  );
};

export default RegisterForFree;
