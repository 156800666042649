import React from "react";
import styles from "../styles.module.scss";
type CardProps = {
  img_src: string;
  title: string;
  description: string;
};
const Card = ({ img_src, title, description }: CardProps) => {
  return (
    <li className={styles.card_container}>
      <img
        className="shrink-0"
        src={img_src}
        alt="card"
        width={48}
        height={48}
      ></img>
      <div className="flex-col gap-[6px]">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </li>
  );
};

export default Card;
