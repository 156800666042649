import styles from "./styles.module.scss";
import bannerMobile from "../../asset/images/katsuwin_mobile.webp";
import bannerPc from "../../asset/images/katsuwin.webp";
import banner_big from "../../asset/images/banner_big.png";
import logo_small from "../../asset//images/logo_small.svg";
import $5000 from "../../asset//images/5000.webp";
import Button from "../Button";
import { REACT_APP_VERSION } from "../../constant/env";

const imageJSX = (() => {
  return REACT_APP_VERSION === "v41" ? (
    <picture>
      <source media="(max-width: 520px)" srcSet={bannerMobile} />
      <source media="(min-width: 519px)" srcSet={bannerPc} />
      <img src={bannerPc} alt="katsuwin net 勝ウィン(KatsuWIN)へようこそ" />
    </picture>
  ) : (
    <img src={banner_big} alt="¥2000 ボーナス" />
  );
})();

const contentJSX = (() => {
  return REACT_APP_VERSION === "v41" ? (
    <div className={styles.content}>
      <div className={styles.title}>
        <div className={styles.first}>
          <img src={logo_small} alt="small logo" />

          <p>勝ウィン(KatsuWIN)へようこそ</p>
        </div>

        <p className={styles.second}>究極にファッショナブル・贅沢な</p>
        <p className={styles.third}>オンラインカジノ</p>
      </div>
      <div className={styles.button_section}>
        現在登録すると新規登録特典がもらえます
        <div>
          <span>¥</span>
          <img src={$5000} alt="5000" />{" "}
          <span className={styles.bonus}>ボーナス</span>
        </div>
        <div className={styles.button}>
          <Button link={process.env.REACT_APP_REGISTER_URL} />
        </div>
      </div>
    </div>
  ) : (
    <Button link={process.env.REACT_APP_REGISTER_URL} />
  );
})();

const Banner = () => {
  return (
    <div
      className={
        REACT_APP_VERSION === "v41" ? styles.wrapper_v41 : styles.wrapper_v40
      }
    >
      {imageJSX}
      {contentJSX}
    </div>
  );
};

export default Banner;
