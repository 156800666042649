import styles from "./styles.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import { FreeMode, Mousewheel } from "swiper/modules";
import { GAMES, GAME_DEALER } from "../../constant";
import Button from "../Button";
import { useState } from "react";
import GameModal from "../GameModal";
import { Link } from "react-router-dom";
import { REACT_APP_VERSION } from "../../constant/env";

const Games = () => {
  const [gameUrl, setGameUrl] = useState("");
  return (
    <>
      {gameUrl && <GameModal gameUrl={gameUrl} setGameUrl={setGameUrl} />}{" "}
      <div
        className={
          REACT_APP_VERSION === "v40" ? styles.wrapper_v40 : styles.wrapper_v41
        }
      >
        <div className={styles.textSection}>
          <h2>数千もの超人気ゲーム</h2>
          <p>
            勝ウィン(KatsuWIN)で、ライブカジノを始めとして、スロットマシン、テーブルゲーム、スキルゲームなど5,000
            種類以上の人気カジノゲームを体験することができます。常にプレイヤー様に最高のサービスを提供します。
          </p>
          <p>下の画像をクリックして今すぐ遊んでみよう。</p>
        </div>
        <div className={styles.games}>
          <Swiper
            modules={[FreeMode, Mousewheel]}
            freeMode={true}
            spaceBetween={16}
            setWrapperSize={true}
            slidesPerView={"auto"}
            initialSlide={GAMES.length / 4}
            mousewheel={true}
          >
            {GAMES.map(({ text, img, url, tag }) => (
              <SwiperSlide
                key={text}
                style={{ width: "auto" }}
                onClick={() => {
                  setGameUrl(url);
                }}
              >
                <div className={styles.game}>
                  <img src={img} alt={text} />
                </div>
                {tag ?? <p>{text}</p>}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={styles.gameDealer}>
          <Swiper
            modules={[FreeMode, Mousewheel]}
            mousewheel={true}
            freeMode={true}
            spaceBetween={16}
            setWrapperSize={true}
            slidesPerView="auto"
            initialSlide={GAME_DEALER.length / 2}
          >
            {GAME_DEALER.map(({ text, img, url, tag }) => (
              <SwiperSlide style={{ width: "auto" }} key={text}>
                <Link to={url}>
                  <div className={styles.dealer}>
                    <img src={img} alt={text} />
                  </div>

                  {tag ?? <p>{text}</p>}
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <Button link={process.env.REACT_APP_REGISTER_URL} />
      </div>
    </>
  );
};

export default Games;
