import { Analytics } from "@katsuwin/share-components";
import "./App.css";

import RootRouter from "./routes/RootRouter";

export type PageState = "SIGN_UP" | "SIGN_UP_SUCCESS";

function App() {
  return (
    <>
      <Analytics
        matomoId={process.env.REACT_APP_MATOMO_ID || ""}
        fbIdsStr={process.env.REACT_APP_PIXEL_IDS || ""}
        gaId={process.env.REACT_APP_GA_ID || ""}
      />
      <RootRouter />
    </>
  );
}

export default App;
