import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/Home";
import { HandleSetPromotionCode } from "@katsuwin/share-components";

const RootRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route
          path="/share"
          element={
            <>
              <HandleSetPromotionCode />
              {<HomePage />}
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RootRouter;
