import React from "react";
import styles from "./styles.module.scss";
const Summary = () => {
  return (
    <section className={styles.section}>
      <h1 className={styles.h1}>
        KatsuWINボーナスから
        <br />
        特別なカジノ旅を始めましょう
      </h1>
      <p className={styles.p}>
        KatsuWINでは、登録ボーナスを始めとして、歓迎入金ボーナス、毎日入金ボーナス、VIPクラブボーナス、ストア現金ボーナス、紹介ボーナスなど、多くのボーナスを提供します。さらに、多くのタスククリアの追加ボーナスもあります。カジノプレイヤーに非常に適しています。ぜひ体験してみてください！
      </p>
    </section>
  );
};

export default Summary;
