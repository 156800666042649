import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { useContext } from "react";
import { AppContext } from "../../context/context";

type ButtonProps = {
  text?: string;
  link?: string;
  onClick?: () => void;
};

const Button = ({ text = "今すぐ登録", link, onClick }: ButtonProps) => {
  const version = process.env.REACT_APP_VERSION;
  const { setSignUpModal } = useContext(AppContext);
  return (
    <>
      {version === "v40" && (
        <Link to={link ?? ""} className={styles.button} onClick={onClick}>
          {text}
        </Link>
      )}
      {version === "v41" && (
        <button
          className={styles.button}
          onClick={onClick || (() => setSignUpModal("SIGN_UP"))}
        >
          {text}
        </button>
      )}
    </>
  );
};

export default Button;
