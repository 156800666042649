import styles from "./styles.module.scss";
import easyAndSafe from "../../asset/images/easyAndSafe.webp";
import JPSupport from "../../asset/images/JPSupport.webp";
import legalLicense from "../../asset/images/legalLicense.webp";
import { REACT_APP_VERSION } from "../../constant/env";
const WHY_CHOOSE__LIST = [
  { text: "簡単かつ安全な入出金", img: easyAndSafe },
  {
    text: "キュラソー島での合法的なライセンスを取得",
    img: legalLicense,
    tag: (
      <p>
        <span className={styles.inCuracao}>
          キュラソー島での
          <br />
        </span>
        合法的なライセンスを取得
      </p>
    ),
  },
  { text: "安心の日本語サポート", img: JPSupport },
];
const WhyChoose = () => {
  return (
    <div
      className={
        REACT_APP_VERSION === "v40" ? styles.wrapper_v40 : styles.wrapper_v41
      }
    >
      <div className={styles.content}>
        <h3>勝ウィン(KatsuWIN) を選ぶ理由</h3>
        <div className={styles.whyChoose}>
          {WHY_CHOOSE__LIST.map(({ text, img, tag }) => (
            <div key={text}>
              <img src={img} alt={text} />
              {tag ?? <p>{text}</p>}
            </div>
          ))}
        </div>
        <div className={styles.man}>
          <p>
            {" "}
            5,000
            種類以上のクラシックなカジノゲーム、ライブカジノ、スキルゲーム、スロットマシン、テーブルゲームなど。
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyChoose;
