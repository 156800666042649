export const cardInfoList = [
  {
    id: "VIP0",
    optionImage: "/vipRewards/options/vip0.webp",
    image: "/vipRewards/vip0.webp",
    promotion: "なし",
    birthday: "なし",
    monthly: "0.30%",
    bet: "0.50%",
  },
  {
    id: "VIP1",
    optionImage: "/vipRewards/options/vip1.webp",
    image: "/vipRewards/vip1.webp",
    promotion: "3,000",
    birthday: "3,000",
    monthly: "0.35%",
    bet: "0.55%",
  },
  {
    id: "VIP2",
    optionImage: "/vipRewards/options/vip2.webp",
    image: "/vipRewards/vip2.webp",
    promotion: "10,000",
    birthday: "10,000",
    monthly: "0.40%",
    bet: "0.60%",
  },
  {
    id: "VIP3",
    optionImage: "/vipRewards/options/vip3.webp",
    image: "/vipRewards/vip3.webp",
    promotion: "30,000",
    birthday: "30,000",
    monthly: "0.45%",
    bet: "0.65%",
  },
  {
    id: "VIP4",
    optionImage: "/vipRewards/options/vip4.webp",
    image: "/vipRewards/vip4.webp",
    promotion: "50,000",
    birthday: "50,000",
    monthly: "0.55%",
    bet: "0.70%",
  },
  {
    id: "VIP5",
    optionImage: "/vipRewards/options/vip5.webp",
    image: "/vipRewards/vip5.webp",
    promotion: "100,000",
    birthday: "100,000",
    monthly: "0.65%",
    bet: "0.80%",
  },
  {
    id: "VIP6",
    optionImage: "/vipRewards/options/vip6.webp",
    image: "/vipRewards/vip6.webp",
    promotion: "150,000",
    birthday: "150,000",
    monthly: "0.80%",
    bet: "1.20%",
  },
  {
    id: "VIP7",
    optionImage: "/vipRewards/options/vip7.webp",
    image: "/vipRewards/vip7.webp",
    promotion: "250,000",
    birthday: "250,000",
    monthly: "1.00%",
    bet: "1.50%",
  },
  {
    id: "VIP8",
    optionImage: "/vipRewards/options/vip8.webp",
    image: "/vipRewards/vip8.webp",
    promotion: "777,777",
    birthday: "777,777",
    monthly: "1.10%",
    bet: "1.60%",
  },
];
