import React, { useContext } from "react";
import logo from "../../asset/images/header_logo.svg";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import x from "../../asset/images/x.svg";
import { AppContext } from "../../context/context";
const Header = () => {
  const version = process.env.REACT_APP_VERSION;
  const { setSignUpModal } = useContext(AppContext);
  return (
    <header className={styles.header}>
      <img src={logo} alt="logo" />
      <div>
        {version === "v41" && (
          <button onClick={() => setSignUpModal("SIGN_UP")}>
            ログイン / 登録
          </button>
        )}

        {version === "v40" && (
          <>
            <button>
              <Link
                to={
                  process.env.REACT_APP_REGISTER_URL ??
                  "https://katsuwin.co/s/bbemn"
                }
              >
                ログイン / 登録
              </Link>
            </button>
            <button>
              <Link to={process.env.REACT_APP_TWITTER_URL || ""}>
                <img src={x} alt="X link:https://twitter.com/katsuwinonline" />
              </Link>
            </button>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
