import React from "react";
import styles from "./style.module.scss";
import Button from "../Button";
import { REACT_APP_VERSION } from "../../constant/env";

const titleJSX = (() =>
  REACT_APP_VERSION === "v40" ? <h1>KatsuWIN.ai</h1> : <h1>KatsuWIN.io</h1>)()

const LuxuryFeatures = () => {
  return (
    <section className={styles.section}>
      <h2>究極にファッショナブル・贅沢な</h2>
      {titleJSX}
      <article>
        <p>
          KatsuWIN正式版が登場、私たちは常にお客様に最高の体験を提供することに専念していて、プレーヤー様の期待により応えるためのアップグレードを行いました。前代未聞のカジノ体験だけではなく、新時代のエンタメを切り開きました。
        </p>
        <p>
          <span>究極の視覚体験：</span>
          全く新しいデザインスタイルと最適化されたユーザーインターフェイスにより、より明瞭でスムーズなゲーム体験をお届けし、類を見ない視覚体験を提供いたします。
        </p>
        <p>
          <span>更なるカジノゲームの選択肢：</span>
          勝ウィン(KatsuWIN)は世界トップクラスのライブカジノゲームプロバイダーを厳選し、ゲームの種類を増やしました。これにより、多様なゲームファンのニーズに応えるべく魅力的な選択肢を準備いたしました。
        </p>
        <p>
          <span>独自の特典とイベント：</span>
          我々は独自の特典やイベントを提供し、皆さまのエンターテイメント体験を充実させるべく、より多くのサプライズボーナスを獲得していただけるチャンスをご用意いたしました。
        </p>
        <p className="mb-[12px]">
          <span>迅速で安全なセキュリティ：</span>
          勝ウィン(KatsuWIN)はセキュリティを向上させることで、ユーザーデータ保護対策を強化し、高度なAI機能を搭載して「独自の2秒決済」を提供し、最速かつ究極の体験をお届けします。
        </p>
        <Button link={process.env.REACT_APP_REGISTER_URL} />
      </article>
    </section>
  );
};

export default LuxuryFeatures;
