import React, { useState } from "react";
import Header from "../../../../components/Header";
import NewAdventure from "../../../../components/NewAdventure";
import ThirdDepositBonus from "../../../../components/ThirdDepositBonus";
import CustomerFeedback from "../../../../components/CustomerFeedback";
import LuxuryFeatures from "../../../../components/LuxuryFeatures";
import VIPRewards from "../../../../components/VIPRewards";
import RewardIntroduction from "../../../../components/RewardIntroduction";
import RegisterForFree from "../../../../components/RegisterForFree";
import Footer from "../../../../components/Footer";
import WhyChoose from "../../../../components/WhyChoose";
import Banner from "../../../../components/Banner";
import Games from "../../../../components/Games";
import { CustomModalProvider, HandleFrozen } from "@katsuwin/share-components";
import { ModalType } from "@katsuwin/share-components/src/types/modal";
import { AppContext } from "../../../../context/context";

// import TestCnClassName from "../../../../components/TestCnClassName";

const HomePage = () => {
  const [signUpModal, setSignUpModal] = useState<ModalType>("");

  return (
    <AppContext.Provider value={{ signUpModal, setSignUpModal }}>
      <Header />
      <Banner />
      <WhyChoose />
      <Games />
      <NewAdventure />
      <ThirdDepositBonus />
      <CustomerFeedback />
      <LuxuryFeatures />
      <VIPRewards />
      <RewardIntroduction />
      <RegisterForFree />
      <Footer />
      <HandleFrozen modalType={signUpModal} />

      {signUpModal === "SIGN_UP" && (
        <CustomModalProvider
          modal_version="v5"
          modal_type={signUpModal}
          set_modal_type={(type: ModalType) => setSignUpModal(type)}
          promotion_code={process.env.REACT_APP_PROMOTION_CODE || ""}
          gaId={process.env.REACT_APP_GA_ID || ""}
        />
      )}
    </AppContext.Provider>
  );
};

export default HomePage;
