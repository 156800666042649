import styles from "./styles.module.scss";
import post_1_Img from "../../asset/images/post_1.webp";
import post_2_Img from "../../asset/images/post_2.webp";
import post_3_Img from "../../asset/images/post_3.webp";
import post_4_Img from "../../asset/images/post_4.webp";
import arrowImg from "../../asset/images/mobile/arrow.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Navigation } from "swiper/modules";

function CustomerFeedback() {
  const postList: { href: string; imgPath: string }[] = [
    {
      href: "https://twitter.com/chaasan81/status/1713816686305198359",
      imgPath: post_1_Img,
    },
    {
      href: "https://twitter.com/Komach__eesen/status/1714063681577783451",
      imgPath: post_2_Img,
    },
    {
      href: "https://twitter.com/masa_ch_/status/1708988023457874044",
      imgPath: post_3_Img,
    },
    {
      href: "https://twitter.com/akamakkyi/status/1713858811080122629",
      imgPath: post_4_Img,
    },
  ];
  return (
    <section className={styles.container}>
      <h2>お客様の声</h2>
      <Swiper
        spaceBetween={17}
        slidesPerView="auto"
        centeredSlides
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        modules={[Navigation]}
        breakpoints={{
          1024: {
            centerInsufficientSlides: true,
            centeredSlides: false,
            slidesOffsetBefore: 17,
          },
        }}
      >
        {postList.map((post) => (
          <SwiperSlide key={post.href} className={styles.post}>
            {process.env.REACT_APP_VERSION === "v41" ? (
              <div>
                <img src={post.imgPath} alt="X post" />
              </div>
            ) : (
              <a href={post.href}>
                <img src={post.imgPath} alt="X post" />
              </a>
            )}
          </SwiperSlide>
        ))}
        <button className={`swiper-button-prev ${styles.button_prev}`}>
          <img src={arrowImg} alt="previous post" />
        </button>
        <button className={`swiper-button-next ${styles.button_next}`}>
          <img src={arrowImg} alt="next post" />
        </button>
      </Swiper>
    </section>
  );
}

export default CustomerFeedback;
