import React from "react";
import Card from "./Card";
import cashPath from "./cash.png";
import crownPath from "./crown.png";
import coupon from "./coupon.png";
import Button from "../../../Button";

const Shop = () => {
  const cardList = [
    {
      img_src: cashPath,
      title: "現金ボーナス",
      description:
        "貯まった「Katsuポイント」で引き換えます!プレイすればするほど「Katsuポイント」が貯まっていきます。",
    },
    {
      img_src: crownPath,
      title: "VIP昇格ボーナス",
      description:
        "VIPレベルに応じてボーナスを獲得することができ、VIP昇格ボーナスは最大で百万円となります。",
    },
    {
      img_src: coupon,
      title: "クーポン",
      description:
        "KatsuWINでは不定期にクーポンコードを配付しており、お客様はEメールまたはKatsuWINの公式SNSなどで各種クーポンコードを獲得いただけます。",
    },
  ];
  return (
    <>
      <h1>KatsuWINストア</h1>
      <div className="px-[23px] pt-[28px] pb-[16px]">
        <p className="pb-[12px]">
          KatsuWINは皆様のご参加を心より歓迎いたします
        </p>
        <p className="pb-[12px]">
          KatsuWINで現金ゲームをプレイするたびに、「Katsuポイント」が貯まるだけでなく、ストアで引き換え可能です。
          同時に、VIPレベルも上がり続けます。
        </p>
        <p className="md:pb-[24px]">
          KatsuWINのショップでは現金ボーナスを含む各種ボーナスや、VIP昇格ボーナス、クーポン、商品などお客様のVIPレベルや、お持ちのKatsuポイントに応じて交換することができる選択肢が広がります。
        </p>
      </div>
      <ul className="flex max-md:flex-col gap-[16px] w-[90%] m-auto pb-[52px] ">
        {cardList.map((cardItem) => {
          return <Card key={cardItem.title} {...cardItem} />;
        })}
      </ul>
      <Button />
    </>
  );
};

export default Shop;
