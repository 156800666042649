import React from "react";
import styles from "./styles.module.scss";
import Button from "../../../Button";
import { REACT_APP_VERSION } from "../../../../constant/env";
import { rowsData_v40, rowsData_v41 } from "../../../../constant";

const rowsData = REACT_APP_VERSION === "v40" ? rowsData_v40 : rowsData_v41;

const Deposit = () => {
  return (
    <>
      <h1 className="pb-[28px]">毎日入金ボーナス</h1>
      <div className="px-[23px] pb-[24px]">
        <p className="pb-[12px]">
          毎日3 回の高額入金ボーナスを獲得しましょう。
        </p>
        <p>
          最初の3つの新規登録歓迎ボーナスを獲得する上に、KatsuWIN
          は毎日3回の入金ボーナスもご用意しており、合計ボーナスは最大 150,000
          円とます。毎回の入金に値します。
        </p>
      </div>
      <div className={styles.step_container}>
        <p>
          ステップ 1 : <strong>入金ページを開きます。</strong>
        </p>
        <p>
          ステップ 2 : <strong>毎日入金ボーナスを選択して入金します。</strong>
        </p>
        <p>
          ステップ 3 :{" "}
          <strong>
            入金完了後、ボーナスウォレットにボーナスが反映されます。
          </strong>
        </p>
      </div>
      <p className="px-[23px] pb-[24px]">
        毎日入金に関するボーナス割合とボーナス上限額の詳細は以下の通りです。
      </p>
      <table>
        <thead>
          <tr>
            <th>每日回数</th>
            <th>ボーナス割合</th>
            <th>最大ボーナス</th>
          </tr>
        </thead>
        <tbody>
          {rowsData.map(({ key, data }) => (
            <tr key={key}>
              <td>{data[0]}</td>
              <td>{data[1]}</td>
              <td>{data[2]}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className="px-[23px] pt-[24px] pb-[64px]">
        大きな賭けと勝利を期待するプレーヤー様にとって、これは素晴らしいチャンスです。
      </p>
      <Button />
    </>
  );
};

export default Deposit;
