import React from "react";
import Layout from "./Layout";
import Deposit from "./Deposit";
import ReferrerProgram from "./ReferrerProgram";
import Shop from "./Shop";
import { IntroductionTitleProps } from "../../../type/rewardIntroduction";
const Introduction = ({
  introductionTitle,
}: {
  introductionTitle: IntroductionTitleProps;
}) => {
  return (
    <Layout>
      {introductionTitle === "毎日入金ボーナス" && <Deposit />}
      {introductionTitle === "フレンド紹介限定特典" && <ReferrerProgram />}
      {introductionTitle === "KatsuWINストア" && <Shop />}
    </Layout>
  );
};

export default Introduction;
