import styles from "./styles.module.scss";
import depositStepsImg_Pc from "../../asset/images/bonus_benefit_pc.webp";
import depositStepsImg_Mobile from "../../asset/images/bonus_benefit_mobile.webp";

function ThirdDepositBonus() {
  return (
    <section className={styles.container}>
      <h3>KatsuWINの</h3>
      <h2>三回歓迎入金ボーナス特典</h2>
      <p>
        KatsuWINでは、初めてご入金いただくお客様に歓迎入金ボーナスをご用意しており、初回入金から三回目まで合計最大15万円のボーナスを獲得できます。手厚い入金ボーナスはオンラインカジノを始めたい方、初心者の方にも最適です。
      </p>
      <strong>
        *毎回の入金キャンペーンの上限額は50,000円となります、20倍のベットが必要。
      </strong>
      <img
        className={styles.img_pc}
        src={depositStepsImg_Pc}
        alt="三回歓迎入金ボーナス特典"
      />
      <img
        className={styles.img_mobile}
        src={depositStepsImg_Mobile}
        alt="三回歓迎入金ボーナス特典"
      />
    </section>
  );
}

export default ThirdDepositBonus;
