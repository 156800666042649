import React from "react";
import referrerStepsPath from "./referrerSteps.png";
import referrerStepsPc from "./referrerStepsPc.webp";
import styles from "./styles.module.scss";
import { useMediaPredicate } from "react-media-hook";
import Button from "../../../Button";

const ReferrerProgram = () => {
  const lessThan640 = useMediaPredicate("(max-width: 640px)");
  return (
    <>
      <h1>
        業界最優の
        <br className="sm:hidden" />
        紹介者プログラム
      </h1>
      <p className="px-[23px] pt-[28px] pb-[20px]">
        Katsuリベートプロジェクトに参加し、フレンドを勝ウィン(KatsuWIN)に招待すると、固定の収入だけでなく、特別なボーナスもゲットできます！一緒にゲームを楽しんで、瞬く間に豊かさを手に入れましょう！
      </p>
      <img
        src={lessThan640 ? referrerStepsPath : referrerStepsPc}
        alt="referrer steps"
        className={styles.referrer_steps}
      />
      <h4 className="py-[20px]">
        Katsuリベートを通じて
        <br className="sm:hidden" />
        無限の収益を生み出すことができます。
      </h4>
      <ol className="pb-[32px]">
        <li className="pb-[12px]">
          フレンドが紹介リンクから登録し、初回入金すると1,000円リベートを獲得することができます
        </li>
        <li className="pb-[12px]">
          ライブカジノやスロットマシンのゲームで、紹介した友達の現金ベット額に基き最大
          0.10% のリベートを獲得することができます。
        </li>
        <li>
          紹介ボーナスには賭け条件はなく、毎週の決済と分配済み後に資金を直接引き出すことができます。
        </li>
      </ol>
      <Button />
    </>
  );
};

export default ReferrerProgram;
