import React, { useState } from "react";
import ButtonWrapper from "./ButtonWrapper";
import { IntroductionTitleProps } from "../../type/rewardIntroduction";
import Summary from "./Summary";
import Introduction from "./Introduction";
import style from "./style.module.scss";

const RewardIntroduction = () => {
  const [introductionTitle, setIntroductionTitle] =
    useState<IntroductionTitleProps>("毎日入金ボーナス");

  return (
    <section className={style.section}>
      <Summary />
      <ButtonWrapper
        setIntroductionTitle={setIntroductionTitle}
        introductionTitle={introductionTitle}
      />
      <Introduction introductionTitle={introductionTitle} />
    </section>
  );
};

export default RewardIntroduction;
