import styles from "./styles.module.scss";
import newAdventureImg from "../../asset/images/new_adventure.webp";


function NewAdventure() {
  return (
    <section className={styles.container}>
      <div className={styles.bg}>
        <img
          src={newAdventureImg}
          alt="新たな冒険の幕開け"
        />
      </div>
    </section>
  );
}

export default NewAdventure;
