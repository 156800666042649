import React from "react";
import styles from "./styles.module.scss";

import { IntroductionTitleProps } from "../../../type/rewardIntroduction";
type ButtonWrapperProps = {
  introductionTitle: IntroductionTitleProps;
  setIntroductionTitle: React.Dispatch<
    React.SetStateAction<IntroductionTitleProps>
  >;
};
const ButtonWrapper = ({
  introductionTitle,
  setIntroductionTitle,
}: ButtonWrapperProps) => {
  const buttonList: IntroductionTitleProps[] = [
    "毎日入金ボーナス",
    "フレンド紹介限定特典",
    "KatsuWINストア",
  ];

  return (
    <section className={styles.section}>
      {buttonList.map((text) => (
        <Button
          key={text}
          text={text}
          introductionTitle={introductionTitle}
          setIntroductionTitle={setIntroductionTitle}
        />
      ))}
    </section>
  );
};

export default ButtonWrapper;

type ButtonProps = {
  text: IntroductionTitleProps;
  introductionTitle: IntroductionTitleProps;
  setIntroductionTitle: React.Dispatch<
    React.SetStateAction<IntroductionTitleProps>
  >;
};
const Button = ({
  text,
  introductionTitle,
  setIntroductionTitle,
}: ButtonProps) => {
  return (
    <button
      className={
        introductionTitle === text ? styles.button_active : styles.button
      }
      onClick={() => {
        setIntroductionTitle(text);
      }}
    >
      {text}
    </button>
  );
};
