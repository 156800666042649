import 花魁ドリーム from "../asset/images/games/game_1.webp";
import シュガーラッシュ from "../asset/images/games/game_2.webp";
import スターライトプリンセス from "../asset/images/games/game_3.webp";
import ゴールデンチケット2 from "../asset/images/games/game_4.webp";
import 花魁ドリームクリスマス from "../asset/images/games/game_5.webp";
import スウィートボナンザ from "../asset/images/games/game_6.webp";
import ヘルカトラススロット from "../asset/images/games/game_7.webp";
import サンクエンティンエックスウェイズ from "../asset/images/games/game_8.webp";
import スウィートアルケミ100 from "../asset/images/games/game_9.webp";
import トームオブマッドネス from "../asset/images/games/game_10.webp";
import ムーンプリンセス from "../asset/images/games/game_11.webp";
import ライズオブオリンポス100 from "../asset/images/games/game_12.webp";
import レイジングレックス3 from "../asset/images/games/game_13.webp";
import ゲートオブオリュンポス from "../asset/images/games/game_14.webp";
import バナナタウン from "../asset/images/games/game_15.webp";
import トワイライトプリンセス from "../asset/images/games/game_16.webp";

import ライトニングバカラ from "../asset/images/games/game_17.webp";
import スピードバカラ from "../asset/images/games/game_18.webp";
import エクストリームライトングルーレット from "../asset/images/games/game_19.webp";
import バカラスピードB from "../asset/images/games/game_20.webp";
import ジャパニーズスピードバカラ from "../asset/images/games/game_21.webp";
import ライブバカラ from "../asset/images/games/game_22.webp";
import エクストリームライトニングバカラ from "../asset/images/games/game_23.webp";

const GAMES = [
  {
    text: "花魁ドリーム",
    img: 花魁ドリーム,
    url: "https://cdn.oryxgaming.com/badges/GMT/_OD1/v2.0.11/index.html?token=token&gameCode=GMT_OIRAN_DREAM&languageCode=ENG&playMode=FUN",
  },
  {
    text: "シュガー · ラッシュ",
    img: シュガーラッシュ,
    url: 'https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=honeyrush100&lang=en_GB&practice=1&channel=desktop&demo=2',
  },
  {
    text: "スターライト · プリンセス",
    img: スターライトプリンセス,
    url: "https://demo.tiger-games.com/demo/link?game_code=vs20starlight&game_provider=Pragmatic Play",
  },
  {
    text: "ゴールデン · チケット 2",
    img: ゴールデンチケット2,
    url: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=goldenticket2&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    text: "花魁ドリーム · クリスマス",
    img: 花魁ドリームクリスマス,
    url: "https://cdn.oryxgaming.com/badges/GMT/_P514/v2.0.48/index.html?cageCode=P514&historyType=INGAME&languageCode=ENG&gameCode=GMT_OIRAN_DREAM_XMAS&token=7324591418845559091&lobbyUrl=OFF&playMode=REAL&isGameBridge=true",
  },
  {
    text: "スウィート · ボナンザ",
    img: スウィートボナンザ,
    url: "https://demo.tiger-games.com/demo/link?game_code=vs20fruitsw&game_provider=Pragmatic Play",
  },
  {
    text: "ヘルカトラス · スロット",
    img: ヘルカトラススロット,
    url: "https://d2drhksbtcqozo.cloudfront.net/casino/games-mt/hellcatraz/index.html?gameid=hellcatraz&jurisdiction=MT&channel=web&moneymode=fun&partnerid=1&beyondplay=true&fullscreen=false",
  },
  {
    text: "サン · クエンティン · エックスウェイズ",
    img: サンクエンティンエックスウェイズ,
    url: "https://demo.nolimitcdn.com/loader/loader-desktop.html?operator=HOMEPAGE_PUBLIC&game=SanQuentin&language=en",
    tag: (
      <p>
        サン・クエンティン・
        <br />
        エックスウェイズ
      </p>
    ),
  },
  {
    text: "スウィート・アルケミ 100",
    img: スウィートアルケミ100,
    url: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=sweetalchemy100&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    text: "トーム・オブ・マッドネス",
    img: トームオブマッドネス,
    url: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=tomeofmadness&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    text: "ムーン・プリンセス",
    img: ムーンプリンセス,
    url: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=moonprincess&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    text: "ライズ・オブ・オリンポス 100",
    img: ライズオブオリンポス100,
    url: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=riseofolympus100&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    text: "レイジング・レックス 3",
    img: レイジングレックス3,
    url: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=ragingrex3&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    text: "ゲート・オブ・オリュンポス",
    img: ゲートオブオリュンポス,
    url: "https://demo.tiger-games.com/demo/link?game_code=vs20olympgate&game_provider=Pragmatic Play",
  },
  {
    text: "バナナ・タウン",
    img: バナナタウン,
    url: "https://d2drhksbtcqozo.cloudfront.net/casino/games-mt/bananatown/index.html?gameid=bananatown&jurisdiction=MT&channel=web&moneymode=fun&partnerid=1&beyondplay=true&fullscreen=false",
  },
  {
    text: "トワイライト・プリンセス",
    img: トワイライトプリンセス,
    url: "https://demo.tiger-games.com/demo/link?game_code=vs20fparty2&game_provider=Pragmatic Play",
  },
];

const GAME_DEALER = [
  {
    text: "ライトニング・バカラ",
    img: ライトニングバカラ,
    url: "https://www.katsuwin.io/",
  },
  {
    text: "スピード・バカラ",
    img: スピードバカラ,
    url: "https://www.katsuwin.io/",
  },
  {
    text: `エクストリーム・ライトニング・ルーレット`,
    img: エクストリームライトングルーレット,
    url: "https://www.katsuwin.io/",
    tag: (
      <p>
        エクストリーム・
        <br />
        ライトニング・ルーレット
      </p>
    ),
  },
  {
    text: "バカラ・スピード B",
    img: バカラスピードB,
    url: "https://www.katsuwin.io/",
  },
  {
    text: "ジャパニーズ・スピード・バカラ",
    img: ジャパニーズスピードバカラ,
    url: "https://www.katsuwin.io/",
    tag: (
      <p>
        ジャパニーズ・スピード・
        <br />
        バカラ
      </p>
    ),
  },
  {
    text: "ライブ - バカラ",
    img: ライブバカラ,
    url: "https://www.katsuwin.io/",
  },
  {
    text: "エクストリーム・ライトニング・バカラ",
    img: エクストリームライトニングバカラ,
    url: "https://www.katsuwin.io/",
    tag: (
      <p>
        エクストリーム・
        <br />
        ライトニング・バカラ
      </p>
    ),
  },
];

export { GAMES, GAME_DEALER };

export const rowsData_v40 = [
  { key: "row_1", data: ["初回", "10%", "50,000円"] },
  { key: "row_2", data: ["二回目", "20%", "25,000円"] },
  { key: "row_3", data: ["三回目", "30%", "25,000円"] },
];

export const rowsData_v41 = [
  { key: "row_1", data: ["初回", "10%", "50,000円"] },
  { key: "row_2", data: ["二回目", "20%", "50,000円"] },
  { key: "row_3", data: ["三回目", "30%", "50,000円"] },
];
